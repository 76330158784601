
const imageCdnLink = 'https://optimy-cdn-devfront.s3.ca-central-1.amazonaws.com/assets/icons';
// const imageCdnLink = `./assets/svg`;
// const imageCdnLink = `https://dx9457ojp328v.cloudfront.net/icons`;
export const ImagePaths = {
  closeIcon: `${imageCdnLink}/close.svg`,
  maximizeIcon: `${imageCdnLink}/maximize.svg`,
  minimizeIcon: `${imageCdnLink}/minimize.svg`,
  noAgent: `${imageCdnLink}/no-agent.svg`,
};

/**
 * Icons still as images:
 * close.svg
 * minimize.svg
 * maximize.svg
 * warning.svg
 * white-close.svg
 * loadingBar-animated.svg
 * microphone.svg
 * video-off.svg
 * aud-vid-icon-white.svg
 * booking-close.svg
 * chat-on.svg
 * addpeople-off.svg
 * control-off.svg
 * settings.svg
 * send-button.svg
 * mic-off.svg
 * microphone-off.svg
 * video.svg
 * chat-off.svg
 * aud-vid-icon.svg
 * aud-vid-icon-white.svg
 * booking-close.svg
 * feedback-agent-avatar.svg
 * the starts were flashing when hover for the first time
 * user-feedback-start.svg
 * user-feedback-star-yellow.svg
 */

export const svgIcons = [
  {
    iconName: 'access-icon',
    iconPath: `${imageCdnLink}/access-icon.svg`,
  },
  {
    iconName: 'add-people',
    iconPath: `${imageCdnLink}/addpeople-off.svg`,
  },
  {
    iconName: 'agent-not-available',
    iconPath: `${imageCdnLink}/agent-not-available.svg`,
  },
  {
    iconName: 'arrow-down',
    iconPath: `${imageCdnLink}/arrow-down.svg`,
  },
  {
    iconName: 'audio-video',
    iconPath: `${imageCdnLink}/audio-video.svg`,
  },
  {
    iconName: 'audio-video-lg',
    iconPath: `${imageCdnLink}/audio-video-lg.svg`,
  },
  {
    iconName: 'bubble-bg',
    iconPath: `${imageCdnLink}/bubble-bg.svg`,
  },
  {
    iconName: 'bubble-close',
    iconPath: `${imageCdnLink}/nudge-close-down.svg`,
  },
  {
    iconName: 'bubble-open',
    iconPath: `${imageCdnLink}/bubble-open.svg`,
  },
  {
    iconName: 'calendar-add',
    iconPath: `${imageCdnLink}/calendar-add.svg`,
  },
  {
    iconName: 'camera',
    iconPath: `${imageCdnLink}/camera.svg`,
  },
  {
    iconName: 'calendar-icon',
    iconPath: `${imageCdnLink}/calendar-icon.svg`,
  },
  {
    iconName: 'call-status-screen-header',
    iconPath: `${imageCdnLink}/video-call-header-icon.svg`,
  },
  {
    iconName: 'call-ended-icon',
    iconPath: `${imageCdnLink}/call-ended.svg`,
  },
  {
    iconName: 'call-not-started-icon',
    iconPath: `${imageCdnLink}/call-not-started.svg`,
  },
  {
    iconName: 'call-scheduled-for-future',
    iconPath: `${imageCdnLink}/call-scheduled-for-future.svg`,
  },
  {
    iconName: 'camera-switch',
    iconPath: `${imageCdnLink}/camera-switch.svg`,
  },
  {
    iconName: 'camera-on',
    iconPath: `${imageCdnLink}/camera-on.svg`,
  },
  {
    iconName: 'camera-off',
    iconPath: `${imageCdnLink}/camera-off.svg`,
  },
  {
    iconName: 'cancel-booking-session',
    iconPath: `${imageCdnLink}/cancel-booking-session.svg`,
  },
  {
    iconName: 'chat',
    iconPath: `${imageCdnLink}/chat-active.svg`,
  },
  {
    iconName: 'chat-new',
    iconPath: `${imageCdnLink}/chat-new.svg`,
  },
  {
    iconName: 'chat-off',
    iconPath: `${imageCdnLink}/chat-off.svg`,
  },
  {
    iconName: 'chat-off-video-call',
    iconPath: `${imageCdnLink}/chat-off-video-call.svg`,
  },
  {
    iconName: 'chat-on-video-call',
    iconPath: `${imageCdnLink}/chat-on-video-call.svg`,
  },
  {
    iconName: 'close',
    iconPath: `${imageCdnLink}/close.svg`,
  },
  {
    iconName: 'cobrowse',
    iconPath: `${imageCdnLink}/cobrowse.svg`,
  },
  {
    iconName: 'config',
    iconPath: `${imageCdnLink}/config.svg`,
  },
  {
    iconName: 'control-off',
    iconPath: `${imageCdnLink}/control-off.svg`,
  },
  {
    iconName: 'copy-icon',
    iconPath: `${imageCdnLink}/copy.svg`,
  },
  {
    iconName: 'corner-video',
    iconPath: `${imageCdnLink}/corner-video.svg`,
  },
  {
    iconName: 'dots-black',
    iconPath: `${imageCdnLink}/dots-black.svg`,
  },
  {
    iconName: 'edit-booking-session',
    iconPath: `${imageCdnLink}/edit-booking-session.svg`,
  },
  {
    iconName: 'email',
    iconPath: `${imageCdnLink}/email.svg`,
  },
  {
    iconName: 'fab-video-icon',
    iconPath: `${imageCdnLink}/fab-video-icon.svg`,
  },
  {
    iconName: 'footer-logo',
    iconPath: `${imageCdnLink}/footer-logo.svg`,
  },
  {
    iconName: 'guest-link-copied-icon',
    iconPath: `${imageCdnLink}/guest-link-copied.svg`,
  },
  {
    iconName: 'hamburger-menu',
    iconPath: `${imageCdnLink}/hamburger-menu.svg`,
  },
  {
    iconName: 'invite-guest-add',
    iconPath: `${imageCdnLink}/invite-guest-add.svg`,
  },
  {
    iconName: 'invite-guest-edit-icon',
    iconPath: `${imageCdnLink}/invite-guest-edit.svg`,
  },
  {
    iconName: 'invite-guest-success-icon',
    iconPath: `${imageCdnLink}/invite-guest-success.svg`,
  },
  {
    iconName: 'invite-people-icon',
    iconPath: `${imageCdnLink}/invite-people-modal-icon.svg`,
  },
  {
    iconName: 'leave',
    iconPath: `${imageCdnLink}/leave-icon.svg`,
  },
  {
    iconName: 'live-video',
    iconPath: `${imageCdnLink}/live-video.svg`,
  },
  {
    iconName: 'live-video-small',
    iconPath: `${imageCdnLink}/live-video-small.svg`,
  },
  {
    iconName: 'maximize',
    iconPath: `${imageCdnLink}/maximize.svg`,
  },
  {
    iconName: 'menu',
    iconPath: `${imageCdnLink}/menu.svg`,
  },
  {
    iconName: 'maximize-video',
    iconPath: `${imageCdnLink}/maximize-video.svg`,
  },
  {
    iconName: 'microphone',
    iconPath: `${imageCdnLink}/microphone.svg`,
  },
  {
    iconName: 'microphone-off',
    iconPath: `${imageCdnLink}/microphone-off.svg`,
  },
  {
    iconName: 'microphone-on',
    iconPath: `${imageCdnLink}/microphone-on.svg`,
  },
  {
    iconName: 'mic-off-icon',
    iconPath: `${imageCdnLink}/mic-off-icon.svg`,
  },
  {
    iconName: 'mic-on-icon',
    iconPath: `${imageCdnLink}/mic-on-icon.svg`,
  },
  {
    iconName: 'minimize',
    iconPath: `${imageCdnLink}/minimize.svg`,
  },
  {
    iconName: 'minimized',
    iconPath: `${imageCdnLink}/minimized.svg`,
  },
  {
    iconName: 'mobile-fab',
    iconPath: `${imageCdnLink}/mobile-fab.svg`,
  },
  {
    iconName: 'new-maximize',
    iconPath: `${imageCdnLink}/new-maximize.svg`,
  },
  {
    iconName: 'new-minimize',
    iconPath: `${imageCdnLink}/new-minimize.svg`,
  },
  {
    iconName: 'no-agent',
    iconPath: `${imageCdnLink}/no-agent.svg`,
  },
  {
    iconName: 'optimy',
    iconPath: `${imageCdnLink}/optimy.svg`,
  },
  {
    iconName: 'paper-plane',
    iconPath: `${imageCdnLink}/paper-plane.svg`,
  },
  {
    iconName: 'participants',
    iconPath: `${imageCdnLink}/participants.svg`,
  },
  {
    iconName: 'people-invite',
    iconPath: `${imageCdnLink}/people-invite.svg`,
  },
  {
    iconName: 'permission-camera',
    iconPath: `${imageCdnLink}/permission-popup-video.svg`,
  },
  {
    iconName: 'permission-mic',
    iconPath: `${imageCdnLink}/permission-popup-mic.svg`,
  },
  {
    iconName: 'phone',
    iconPath: `${imageCdnLink}/phone.svg`,
  },
  {
    iconName: 'phone-hangup',
    iconPath: `${imageCdnLink}/phone-hangup.svg`,
  },
  {
    iconName: 'phone-slash',
    iconPath: `${imageCdnLink}/phone-slash.svg`,
  },
  {
    iconName: 'queue-leave',
    iconPath: `${imageCdnLink}/queue-leave.svg`,
  },
  {
    iconName: 'refresh',
    iconPath: `${imageCdnLink}/refresh.svg`,
  },
  {
    iconName: 'save-link-icon',
    iconPath: `${imageCdnLink}/save-link-icon.svg`,
  },
  {
    iconName: 'schedule-add-to-google',
    iconPath: `${imageCdnLink}/schedule-add-to-google-calendar.svg`,
  },
  {
    iconName: 'schedule-download-ics-file',
    iconPath: `${imageCdnLink}/schedule-download-calendar-file.svg`,
  },
  {
    iconName: 'schedule-live-session-calendar',
    iconPath: `${imageCdnLink}/schedule-live-session-calendar.svg`,
  },
  {
    iconName: 'schedule-succsess',
    iconPath: `${imageCdnLink}/schedule-live-session-success.svg`,
  },
  {
    iconName: 'scroll-down',
    iconPath: `${imageCdnLink}/mccarthy-segment-drop-down.svg`,
  },
  {
    iconName: 'security-camera',
    iconPath: `${imageCdnLink}/security-camera.svg`,
  },
  {
    iconName: 'security-info',
    iconPath: `${imageCdnLink}/security-info.svg`,
  },
  {
    iconName: 'security-mic',
    iconPath: `${imageCdnLink}/security-mic.svg`,
  },
  {
    iconName: 'session-link-expired',
    iconPath: `${imageCdnLink}/session-link-expired.svg`,
  },
  {
    iconName: 'settings',
    iconPath: `${imageCdnLink}/settings.svg`,
  },
  {
    iconName: 'share',
    iconPath: `${imageCdnLink}/share.svg`,
  },
  {
    iconName: 'speaker',
    iconPath: `${imageCdnLink}/speaker.svg`,
  },
  {
    iconName: 'success',
    iconPath: `${imageCdnLink}/success-icon.svg`,
  },
  {
    iconName: 'switch-video',
    iconPath: `${imageCdnLink}/switch-video.svg`,
  },
  {
    iconName: 'switch-camera',
    iconPath: `${imageCdnLink}/switch-camera.svg`,
  },
  {
    iconName: 'three-dots-menu',
    iconPath: `${imageCdnLink}/three-dots-menu.svg`,
  },
  {
    iconName: 'thick-close',
    iconPath: `${imageCdnLink}/thick-close.svg`,
  },
  {
    iconName: 'user',
    iconPath: `${imageCdnLink}/user.svg`,
  },
  {
    iconName: 'user-plus',
    iconPath: `${imageCdnLink}/user-plus.svg`,
  },
  {
    iconName: 'video',
    iconPath: `${imageCdnLink}/video.svg`,
  },
  {
    iconName: 'video-fab-icon',
    iconPath: `${imageCdnLink}/video-fab-white.svg`,
  },
  {
    iconName: 'video-off',
    iconPath: `${imageCdnLink}/video-off.svg`,
  },
  {
    iconName: 'white-close',
    iconPath: `${imageCdnLink}/white-close.svg`,
  },
  {
    iconName: 'white-minimize',
    iconPath: `${imageCdnLink}/white-minimize.svg`,
  }
];
