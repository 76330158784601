export const environment = {
    baseApiUrl: 'https://devfront-optimy-pwa.com',
    CDN_URL: 'https://d2v9c5gw657g9w.cloudfront.net/icons',
    gaKey: 'GTM-KQMJP8B',
    cobrowseLicense: 'VaU4lJXO8ZESsg',
    appVersion: require('../../package.json').version,
    snowplowCollectorEndpoint: 'dev.sp.optimy.ai',
    logRocketProjectIdentification: 'optimy/plugin-devfront',
    environmentName: 'devfront',
    production: false,
    devBack: false,
    devFront: true,
    uat: false,
    qa: false,
    local: false,
    agentStatusApi: 'https://lambda.devfront-optimy-pwa.com/agentstatus'
};
