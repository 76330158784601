import { ColorService } from '../../core/services/utils/color.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { lastValueFrom, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import {
  AgentResponseData,
  AgentStatus,
  AgentStatusUpdateSocket,
  LangObjStructure, Locale,
  Nudge
, PluginPosition, Positions } from '../../core/constants/agent.modal';
import { CallStatus, FabButtons, FabButtonType, OutbounRejectReq } from '../../core/constants/call.modal';
import {
  CallInfoStates,
  CobrowseInfo,
  Context,
  GuestStatus,
  InCallState,
  InviteCodeParams,
  InviteData,
  InviteLinkStatus,
  LanguageConfig,
  LanguageType,
  OptimyScreenValuesOfBooking,
  OutboundInfo,
  PluginConfig,
  PluginStates,
  QueryParamsForWhiteList,
  QueryParamsOptimyScreen,
  ResizeState,
  RoomInfo,
  RoomInfoStates,
  RoutesUrls,
  UserInfo
} from '../../core/constants/common.enum';
import { Constants } from '../../core/constants/constant';
import { English, French } from '../../core/constants/language';
import { ResizeService } from '../../core/services/resize/resize.service';
import { SharedService } from '../../core/services/shared/shared.service';
import { SnowplowService } from '../../core/services/snowplow/snowplow.service';
import { SocketService } from '../../core/services/socket/socket.service';
import { UtilsService } from '../../core/services/utils/utils.service';
import { VideoCallService } from '../../core/services/video-call/video-call.service';
import { LoggingService } from '../../core/services/logging/logging.service';
import { RecordingService } from '../../core/services/recording/recording.service';
import {
  GtmTrackers,
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels,
  SnowplowTrackerProperties,
  SnowplowTrackerSchemas
} from '../../core/constants/trackerLabels';
import { GtmService } from '../../core/services/gtm/gtm.service';

declare global {
  interface Window { angularComponentRef: any; }
}

@Component({
  selector: 'optimy-launcher',
  styleUrls: ['./optimy-launcher.component.scss'],
  templateUrl: './optimy-launcher.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('200ms ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('500ms ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class OptimyLauncherComponent implements OnInit, AfterViewChecked, OnDestroy {
  public isMobile = false;
  public mobileTablet = false;
  public showLauncher = true;
  public showAppWindow: boolean;
  public agentDetails: AgentResponseData;
  public agentsOnline = false;
  public pluginPosition: PluginPosition;
  public isFirst: boolean;
  public openNudge = false;
  public invitationCode: string;
  public roomInfo: RoomInfo;
  public timeLeft: number;
  public queryParamsList = QueryParamsForWhiteList;
  public showBooking = false;
  public language: string;
  public bookingStatus: string;
  public languageConfig: LanguageConfig;
  public showCancelBookingModal: boolean;
  public showFabIcon: boolean;
  public isOutboundCall: boolean;
  public onCall: boolean;
  public ip: string;
  public noHeaderFooter = false;
  public expiredLink: boolean;
  public showLauncherTriggered = false;
  public hideFab = false;
  public currentNudge: Nudge;
  public currentNudgeIndex: number;
  public pluginLoadTime: Date;
  public nudgeContent: SafeHtml;
  public fabTitle: string;
  public isCallWaiting = false;
  public agentJoining = false;
  public fabCollapse = false;
  public fabButtons = FabButtons;
  public buttonList: FabButtonType[] = [];
  public queuePositionId: number;

  languageObj: LangObjStructure;
  languageLocale: Locale | any;

  public showLeaveMessage: boolean;
  public queueId: number;
  public showNoAgentModal = false;

  public destroy$: Subject<boolean> = new Subject<boolean>();
  public openModal: Subject<void> = new Subject<void>();
  public closeModal: Subject<void> = new Subject<void>();

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties,
    schemas: SnowplowTrackerSchemas
  };

  @ViewChild('pluginDiv') public pluginDiv: ElementRef;
  @ViewChild('mobileNudge') public mobileNudge: ElementRef;

  public maximize = false;
  public inCall = false;

  constructor(
    @Inject(Constants.pluginConfig) pluginConfig: PluginConfig,
    private utils: UtilsService,
    private colorService: ColorService,
    private videoCallService: VideoCallService,
    private resizeService: ResizeService,
    private router: Router,
    private sharedService: SharedService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private socketService: SocketService,
    private zone: NgZone,
    private snowplowService: SnowplowService,
    private recordingService: RecordingService,
    private loggingService: LoggingService,
    private sanitizer: DomSanitizer,
    private gtmService: GtmService) {
    window.angularComponentRef = {
      zone: this.zone,
      componentFn: (value: string, queueId?: number) => this.optimyScreenCallFromOutside(value, queueId),
      component: this
    };
    if (pluginConfig) {
      this.languageConfig = pluginConfig.languageConfig
    }
    this.socketService.agentStatusUpdateSocket
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        {
          next: (data: AgentStatusUpdateSocket) => {
            if (data?.tenant_id?.includes(this.agentDetails?.tenant_id)) {
              this.getAgentInfo(true, true);
            }
          },
        });
    this.socketService.roomClose
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo) as RoomInfo;
        if (roomInfo?.roomName === data.channelSid) {
          this.inCall = false;
        }
      });
  }


  private subscribeToResizeSub(): void {
    this.resizeService.resizeSub.pipe(takeUntil(this.destroy$)).subscribe((resp: ResizeState) => {
      this.maximize = resp.isMaximized;
    });
  }

  private subscribeToInCall(): void {
    this.videoCallService.inCall$.pipe(takeUntil(this.destroy$)).subscribe((resp: InCallState) => {
      this.inCall = resp.inCall;
    });
  }

  public ngOnInit(): void {
    this.isMobile = this.utils.checkAndGetDeviceType() === 'mobile';
    this.mobileTablet = this.isOnMobile();
    this.pluginLoadTime = new Date();
    this.snowplowService.initializeSnowplow();
    this.appHeight();
    this.colorService.initializeColorSystem().then(() => {
      this.updateIp();
      this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
      this.pluginPosition = this.agentDetails.fab_config?.position_config;
      this.setPluginPosition();
      this.findClientLanguage();
      this.setLanguage(this.language);
      this.utils.setLocalVal(null, [PluginStates.guestStatus], [GuestStatus.available]);
      this.subscribeToResizeSub();
      this.subscribeToInCall();
      this.videoCallService.closePluginWithoutFeedback$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.closePluginWithoutFeedback();
      });

      this.updateLocalVariables();
      this.isFirst = true;
      this.invitationCode = this.getInviteCode();
      this.fabTitle = this._getFebTitle();

      this.agentsOnline = !!this.agentDetails.are_agents_online;
      if (this.invitationCode) {
        this.getRoomFromCode();
      } else {
        this.checkRoomStatus();
      }
    });
    this.utils?.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.isBookingConfirmed]);
    this.sharedService.showLauncher$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        if (data) {
          this.utils.setLocalVal(null, [PluginStates.guestStatus], [GuestStatus.available]);
          this.noHeaderFooter = false;
          this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.deviceInfo, PluginStates.outboundFirstInitiated, PluginStates.isBookingConfirmed]);
        }
        this.isFirst = false;
        this.onCall = false;
        this.agentJoining = false;
        this.showLauncher = data;
        this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
        this.showBooking = false;
        this.expiredLink = false;
        this.isOutboundCall = false;
        if (this.agentDetails && this.checkFabHide() && this.showLauncher) {
          const context: Context[] = this.getFabContext();
          this.snowplowService.trackStructEvent(this.spTracker.labels.inboundFab, this.spTracker.labels.screenShown,
            this.agentDetails.are_agents_online ? this.spTracker.labels.genericFabWithAgents : this.spTracker.labels.genericFabNoAgent,
            this.spTracker.labels.version, 1, context);
          this.showLauncherTriggered = true;
        }
        this.isCallWaiting = false;
        this.fabCollapse = false;
        this.hideFabItems();
      });

    this.sharedService.isMinimized$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.showAppWindow = !data;
        this.utils.setLocalVal(PluginStates.callInfo, [CallInfoStates.isWindowMinimized], [data]);
      });

    this.sharedService.closeCall$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.close();
      });

    this.sharedService.timeLeft$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: number) => {
        this.timeLeft = data;
      });

    this.sharedService.checkState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.updateLocalVariables();
        if (data) {
          this.getSetRoomInfo();
        }
      });

    this.sharedService.hideFab$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.showFabIcon = false;
      });

    this.sharedService.userJoinedQueue$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.fabCollapse = data;
      });


    this.sharedService.showBookingAndCheckPreviousState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        if (data) {
          this.showBooking = true;
        } else {
          this.showBooking = false;
          if (this.bookingStatus) {
            this.utils.removeLocalStoreVal(
              Constants.optimyLocalStore,
              [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.bookingSessionInfo, PluginStates.outboundFirstInitiated]);
            this.navigateToClose();
          } else {
            const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
            const invitationCode = this.utils.getCodeFromInviteLink(roomInfo?.inviteLink);
            if (invitationCode) {
              this.getSetRoomInfo();
            } else {
              this.utils.removeLocalStoreVal(
                Constants.optimyLocalStore,
                [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.bookingSessionInfo, PluginStates.outboundFirstInitiated]);
              this.navigateToClose();
            }
          }
        }
      });

    this.socketService.outboundInitiated.pipe(takeUntil(this.destroy$)).subscribe((response: OutboundInfo) => {
      this.openNudge = false;
      const guestStatus = this.utils.getLocalVal(PluginStates.guestStatus);
      const guestSnowplowId = this.snowplowService.getSnowplowUserId();
      if (guestSnowplowId === response.snowplow_domain_userid) {
        if (guestStatus === GuestStatus.available && !this.isGuestOnCallOrQueue()) {
          this.gtmService.sendGtm(GtmTrackers.optimyOutboundInitiated);
          this.initializeOutboundCall(response);
        } else {
          const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
          if (roomInfo) {
            this.gtmService.sendGtm(GtmTrackers.optimyOutboundRejected);
            const req: OutbounRejectReq = {
              room_name: response?.roomName,
              auto_reject: 1,
            }
            this.videoCallService.rejectedOutbound(roomInfo?.guestToken, req).pipe(takeUntil(this.destroy$)).subscribe(() => {
            });
            return;
          }
          return;
        }
      }
    });

    this.sharedService.outboundCall$
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: boolean) => {
        this.onCall = response;
        this.isOutboundCall = false;
      });

    this.sharedService.hideHeaderAndFooter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.noHeaderFooter = data;
      });

    this.sharedService.displayMinimize$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.showLauncher = false;
          this.isOutboundCall = true;

        }
      });
    this.socketService.cobrowseInitiated.pipe(takeUntil(this.destroy$)).subscribe((res: CobrowseInfo) => {
      if(res.clientIdentity === this.snowplowService.getSnowplowUserId()) {
        (<any>window).CobrowseIO.customData = {...(<any>window).CobrowseIO.customData};
      }
    })
  }

  isOnMobile() {
    return this.utils.isOnMobile();
  }

  private updateLocalVariables(): void {
    this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    this.isOutboundCall = !!this.roomInfo?.isOutboundCall;
    if (this.isOutboundCall) {
      this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
      this.agentsOnline = !!this.agentDetails.are_agents_online;
    }
    this.onCall = this.utils.isGuestOnCall();
  }

  private closeCobrowse(): void {
    const roomName = this.utils.getSessionVal(RoomInfoStates.roomName);
    const request: CobrowseInfo = {
      roomName: roomName,
      cobrowseAction: 'ended',
      clientIdentity: ''
    };
    this.socketService.cobrowseInitiated.next(request);
  }

  private isGuestOnCallOrQueue(): boolean {
    const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    return roomInfo && (this.utils.isGuestOnCall() || roomInfo.roomStatus === CallStatus.pending ||
      roomInfo.roomStatus === CallStatus.ad_hoc || !!roomInfo.queuePositionId);
  }

  initializeOutboundCall(outboundInfo: OutboundInfo): void {
    this.loggingService.initializeLogging$.next(true);
    this.showAppWindow = true;
    this.updateLocalStoreVariablesAndNavigate(outboundInfo);
  }

  private async getUserInfoForOutboundCall(outboundInfo: OutboundInfo): Promise<UserInfo> {
    const ip = await lastValueFrom(this.utils.getIPAddress());
    const agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    this.showAppWindow = true;

    return {
      ip,
      tenantid: agentDetails.tenant_id,
      client_user_id: outboundInfo.snowplow_domain_userid,
      queue_id: 0,
      user_agent: navigator.userAgent,
    } as UserInfo;
  }

  private async updateLocalStoreVariablesAndNavigate(outboundInfo: OutboundInfo) {
    const user = await this.getUserInfoForOutboundCall(outboundInfo);
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.queueInviteCode], [outboundInfo.invitecode]);
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.isOutboundCall], [true]);
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.userInfo], [user]);
    this.utils.setLocalVal(PluginStates.callInfo, [CallInfoStates.audio, CallInfoStates.video], [false, false]);

    this.router.navigate([{ outlets: { plugin: [RoutesUrls.video_call] } }], {});
  }

  public ngOnDestroy(): void {
    window.angularComponentRef = null;
    this.destroy$.next(true);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }


  private emitInteractionDetectedForRecording() {
    this.recordingService.startRecordInteractionAction$.next(true);
  }

  getInviteCode() {
    const currentLocation = location.href;
    this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    if (currentLocation.includes(InviteCodeParams.code) || currentLocation.includes(InviteCodeParams.optimyCode)) {
      this.emitInteractionDetectedForRecording();
      return this.checkAndGetInviteCode('code', null);
    } else if (this.roomInfo?.queue_invite_code) {
      return this.roomInfo.queue_invite_code;
    } else {
      return this.checkAndGetInviteCode('code', this.roomInfo?.inviteLink);
    }
  }

  findClientLanguage() {
    const defaultLang =  this.agentDetails?.fab_config?.default_language ? this.agentDetails?.fab_config?.default_language : 'en';
    const regex = new RegExp('\/fr/\i*');
    if (this.languageConfig) {
      if (this.languageConfig.value) {
        this.language = this.languageConfig.value;
      } else if (this.languageConfig.type === LanguageType.localstorage) {
        this.language = localStorage.getItem(this.languageConfig.key) || defaultLang;
      } else if (this.languageConfig.type === LanguageType.cookies) {
        const lang = document.cookie?.split(';')
            .filter((x) => x.includes(this.languageConfig.key))[0]?.split('=')[1];
        this.language = lang ? lang : defaultLang;
      } else {
        this.language = location.href.match(regex) ? 'fr' : 'en';
      }
    } else {
      this.language = defaultLang;
    }
  }

  setLanguage(lang: string) {
    this.translate.setDefaultLang(lang);
    this.utils.setLocalVal(null, [PluginStates.language], [lang]);
    this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    this.languageObj = lang === 'en' ? English : French;
    if(this.agentDetails?.language_config) {
      this.languageLocale = lang === 'en' ? this.agentDetails?.language_config?.en : this.agentDetails?.language_config?.fr;
      if (this.languageLocale) {
        const keysToUpdate = Object.keys(this.languageLocale);
        keysToUpdate.forEach((key: string)=> {
          this.updateLanguageFile(this.languageObj, key);
        })
      }
    }
    this.translate.setTranslation(lang, this.languageObj)
  }
  updateLanguageFile(langObj: LangObjStructure, key: string){
    const parentKey: string[] =  Object.keys(langObj).filter((val: string)=> {
       return Object.keys(langObj[val]).includes(key);
    })
    this.languageObj[parentKey[0]][key] = this.languageLocale[key];
  }

  checkRoomStatus() {
    this.isFirst = true;
    this.getAgentInfo(false, true).then(() => {
      const optimyScreen = this.getUrlParameter('optimy_screen', null);
      const inviteCode = this.checkAndGetInviteCode('code', null);
      if (!this.getBookingConfigStatus() && !this.agentDetails.are_agents_online && !this.getLeaveMessageConfigStatus()) {
        this.showLauncher = false;
        this.noHeaderFooter = true;
      } else if (OptimyScreenValuesOfBooking.includes(optimyScreen)) {
        this.loggingService.initializeLogging$.next(true);
        this.isFirst = false;
        this.bookingStatus = optimyScreen;
        this.utils.setSessionVal([RoomInfoStates.queueInviteCode], [this.checkAndGetInviteCode('code', null)]);
        const bookingUrl = this.utils.checkAndRedirectToBooking();
        if (!bookingUrl) {
          if (this.bookingStatus.includes(QueryParamsOptimyScreen.cancelBooking) && inviteCode) {
            this.showCancelBookingModal = true;
            this.openModal.next();
          } else {
            if (this.getBookingConfigStatus()) {
              this.redirectToBooking(this.bookingStatus, false);
            } else if (this.getLeaveMessageConfigStatus()) {
              this.showLeaveMessageModal()
            } else {
              this.cleanCallInfoFromLocalStorage();
              this.sharedService.showAppLauncher(true);
            }
          }
        }
      } else if (optimyScreen === QueryParamsOptimyScreen.joinQueue) {
        this.checkIfShowFabIcon();
        this.onClickPlugin(false, true);
        let context: Context[] = this.getLaunchSchema(this.spTracker.schemas.typeLink, this.spTracker.labels.joinQueue);
        if(this.agentDetails.are_agents_online) {
          const additionalSchema: Context = this.getAgentSchema();
          context = [...context, additionalSchema];
        }
        this.snowplowService.trackStructEvent(this.spTracker.labels.inboundExternal, this.spTracker.labels.joinQueue,
          this.agentDetails.are_agents_online ? this.spTracker.labels.externalLinkWithAgent : this.spTracker.labels.externalLinkNoAgent,
          this.agentDetails.are_agents_online ? this.spTracker.labels.agentsAvailable : '', this.agentDetails.agents_available_now, context);
      } else if (optimyScreen === QueryParamsOptimyScreen.messageus) {
        this.loggingService.initializeLogging$.next(true);
        this.showAppWindow = true;
        this.showLauncher = false;
        this.noHeaderFooter = true;
        this.router.navigate([{ outlets: { plugin: [RoutesUrls.message_us] } }], { queryParams: { optimy_screen: 'messageus' } });
      }
    });
    this.showAppWindow = false;
    this.showLauncher = true;
  }

  optimyScreenCallFromOutside(value: string, queueId?: number) {
    this.zone.run(() => {
      if (value === QueryParamsOptimyScreen.joinQueue) {
        this.checkIfShowFabIcon();
        this.onClickPlugin(false, true);
        let context: Context[] = this.getLaunchSchema(this.spTracker.schemas.typeFunction, this.spTracker.labels.joinQueue);
        if(this.agentDetails.are_agents_online) {
          const additionalSchema: Context = this.getAgentSchema();
          context = [...context, additionalSchema];
        }
        this.snowplowService.trackStructEvent(this.spTracker.labels.inboundExternal, this.spTracker.labels.joinQueue,
          this.agentDetails.are_agents_online ? this.spTracker.labels.externalFunctionWithAgent : this.spTracker.labels.externalFunctionNoAgent,
          this.agentDetails.are_agents_online ? this.spTracker.labels.agentsAvailable : '', this.agentDetails.agents_available_now, context);
      } else if (value === QueryParamsOptimyScreen.booking && this.getBookingConfigStatus() ||
          (value === QueryParamsOptimyScreen.leaveMessage && this.getBookingConfigStatus() && !this.getLeaveMessageConfigStatus())) {
        this.redirectToBooking(value, true);
        this.loggingService.initializeLogging$.next(true);
      } else if ( value === QueryParamsOptimyScreen.leaveMessage && this.getLeaveMessageConfigStatus() ||
          (value === QueryParamsOptimyScreen.booking && this.getLeaveMessageConfigStatus() && !this.getBookingConfigStatus())) {
        this.queueId = queueId ? queueId : this.agentDetails?.default_queue_id;
        this.utils.storeValueForUserInfoAndDevice(this.queueId, this.agentDetails?.tenant_id);
        let context: Context[] = this.getLaunchSchema(this.spTracker.schemas.typeFunction, this.spTracker.actions.leaveMessage);
        if (queueId) {
          context[0].data.queue_id = queueId;
        }
        if(this.agentDetails.are_agents_online) {
          const additionalSchema: Context = this.getAgentSchema();
          context = [...context, additionalSchema];
        }
        this.snowplowService.trackStructEvent(this.spTracker.labels.inboundExternal, this.spTracker.actions.leaveMessage,
            this.agentDetails.are_agents_online ? this.spTracker.labels.externalFunctionWithAgent : this.spTracker.labels.externalFunctionNoAgent,
            this.agentDetails.are_agents_online ? this.spTracker.labels.agentsAvailable : '', this.agentDetails.agents_available_now, context);
        this.showLeaveMessageModal(this.queueId);
      }
    });
  }

  redirectToBooking(bookingStatus: string, isFn: boolean) {
    this.showBooking = true;
    this.setVariablesForDirectUrl();
    this.navigateToBooking(bookingStatus);
    if (bookingStatus === QueryParamsOptimyScreen.booking) {
      let context: Context[] = this.getLaunchSchema(isFn ? this.spTracker.schemas.typeFunction: this.spTracker.schemas.typeLink, this.spTracker.labels.booking);
      if(this.agentDetails.are_agents_online) {
        const additionalSchema: Context = this.getAgentSchema();
        context = [...context, additionalSchema];
      }
      this.snowplowService.trackStructEvent(this.spTracker.labels.bookingExternal, this.spTracker.labels.booking,
        this.agentDetails.are_agents_online ?
          (isFn ? this.spTracker.labels.externalFunctionWithAgent : this.spTracker.labels.externalLinkWithAgent) :
          (isFn ? this.spTracker.labels.externalFunctionNoAgent : this.spTracker.labels.externalLinkNoAgent), '', '', context);
    }
  }

  setSessionValForTrackLabel(label: string, isExternalLink?: boolean) {
    if (isExternalLink) {
      this.utils.setSessionVal([PluginStates.externalLinkTrackLabel], [label]);
    }
  }

  checkIfShowFabIcon() {
    if (!this.agentsOnline && this.getBookingConfigStatus()) {
      this.showFabIcon = true;
    }
  }

  setVariablesForDirectUrl() {
    this.showLauncher = false;
    this.showAppWindow = true;
  }

  getAgentInfo(isSocketHit: boolean, showNudge?: boolean) {
    const agentInfo = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    return new Promise((resolve) => {
      if (!agentInfo || isSocketHit) {
        this.utils.getAgentStatusFormRedisCaChe().subscribe((res: AgentResponseData | any) => {
          // when we implement state management add this function call to effects and remove from here
          this.setValueForAgentDetails(isSocketHit, res, showNudge);
          resolve(0);
        }, error => {
          console.log('agent status error', error);
          resolve(0);
        });
      } else {
        this.setValueForAgentDetails(isSocketHit, agentInfo, showNudge);
        resolve(0);
      }
    });
  }

  setValueForAgentDetails(isSocketHit: boolean, agentInfo: AgentResponseData, showNudge?: boolean) {
    this.utils.setAgentStatusToSessionStorage(agentInfo);
    this.sharedService.updatedAgentInfo(agentInfo);
    this.zone.run(() => {
      this.agentDetails = agentInfo;
      this.openNudge = false;
      this.fabTitle = this._getFebTitle();
      this.agentsOnline = !!this.agentDetails.are_agents_online;
    });
    if (showNudge && this.isFirst) {
      this.executeNudgeLogic(0);
    }
    if (this.agentDetails && this.checkFabHide() && this.showLauncher && !this.showLauncherTriggered && !isSocketHit) {
      const context: Context[] = this.getFabContext();
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundFab, this.spTracker.labels.screenShown,
        this.agentDetails.are_agents_online ? this.spTracker.labels.genericFabWithAgents : this.spTracker.labels.genericFabNoAgent,
        this.spTracker.labels.version, 1, context);
    }
  }

  private _getFebTitle(): string {
    return this.agentDetails?.fab_config?.fab_caption ?
      this.agentDetails.fab_config?.fab_caption[this.language.toLowerCase()] : '';
  }

  executeNudgeLogic(index: number) {
    const nudgeList = this.utils.getSessionVal(AgentStatus.agentStatusInfo)?.fab_config?.nudges?.[this.utils.checkAndGetDeviceType()]
    if (nudgeList?.length && nudgeList[index]) {
      for (let i = index; i < nudgeList?.length; i++) {
        if (this.checkNudgeEligibility(nudgeList[i])) {
          this.currentNudge = nudgeList[i];
          this.nudgeContent = this.sanitizer.bypassSecurityTrustHtml(this.currentNudge.content[this.language]?.content);
          this.currentNudgeIndex = i;
          this.showNudgeFn();
          break;
        }
      }
    }
  }

  updateNudgePosBasedConfig(pluginPosition: PluginPosition) {
    this.mobileNudge.nativeElement.style.bottom = pluginPosition?.bottom ?? '16px';
    switch (pluginPosition?.position) {
      case Positions.bottom_left:
        this.mobileNudge.nativeElement.style.left = (pluginPosition?.left ?? '16px');
        break;
      case Positions.bottom_right:
        this.mobileNudge.nativeElement.style.right = (pluginPosition?.right ?? '16px');
        break;
      default:
        this.mobileNudge.nativeElement.style.right = (pluginPosition?.right ?? '16px');
        break;
    }
  }

  showNudgeFn() {
    const timeDiff = moment(moment(new Date(), 'HH:mm:ss').diff(moment(this.pluginLoadTime, 'HH:mm:ss'))).format('ss');
    if (+timeDiff < this.currentNudge.appear_delay) {
      setTimeout(() => {
        this.openNudge = this.isFirst;
        if (this.openNudge) {
          if (this.currentNudge?.position_config) {
            this.updateNudgePosBasedConfig(this.currentNudge?.position_config)
          }
          const context: Context[] = this.getNudgeContext(this.spTracker.labels.screenShown);
          this.snowplowService.trackStructEvent(this.spTracker.labels.inboundNudge, this.spTracker.labels.screenShown,
            this.agentDetails.are_agents_online ? this.spTracker.labels.genericNudgeWithAgent :
              this.spTracker.labels.genericNudgeNoAgent, this.spTracker.labels.version,
              this.agentDetails.fab_config?.nudge_config_version, context);
          if (this.currentNudge.duration_on_screen && this.openNudge) {
            setTimeout(() => {
              this.nudgeClose(true);
            }, this.currentNudge.duration_on_screen * 1000);
          }
        }
      }, (this.currentNudge.appear_delay - +timeDiff) * 1000);
    }
  }

  checkNudgeEligibility(nudge: Nudge): boolean {
    const nudgeContentAvailable = !!nudge?.content[this.language];
    const isDismissed = this.utils.getSessionVal(AgentStatus.dismissedNudges)?.includes(
      nudge?.content[this.language].nudge_identifier);
    let checkWhiteListBlackList = false;
    if (this.checkFabHide()) {
      if (!nudge.blacklist?.length && !nudge.whitelist?.length) {
        checkWhiteListBlackList = true;
      } else if (nudge.blacklist?.length && !nudge.whitelist?.length) {
        checkWhiteListBlackList = this.checkFabHideOrShow(nudge?.blacklist, false);
      } else if ((!nudge.blacklist?.length && nudge.whitelist?.length) ||
        (nudge.blacklist?.length && nudge.whitelist?.length)) {
        checkWhiteListBlackList = this.checkFabHideOrShow(nudge?.whitelist, true);
      }
    }
    return nudgeContentAvailable && !isDismissed && checkWhiteListBlackList;
  }

  nudgeClose(autoClose: boolean) {
    this.openNudge = false;
    const context: Context[] = this.getNudgeContext(autoClose ? this.spTracker.labels.autoClose : this.spTracker.labels.close);
    if (autoClose) {
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundNudge, this.spTracker.labels.autoClose,
        this.agentDetails.are_agents_online ? this.spTracker.labels.genericNudgeWithAgent :
          this.spTracker.labels.genericNudgeNoAgent, this.spTracker.labels.durationOnScreen,
          this.currentNudge.duration_on_screen, context);
    } else {
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundNudge, this.spTracker.labels.close,
        this.agentDetails.are_agents_online ? this.spTracker.labels.genericNudgeWithAgent :
          this.spTracker.labels.genericNudgeNoAgent,'','', context);
    }
    const nudgeId = this.currentNudge.content[this.language]?.nudge_identifier;
    let dismissedNudgeList: string[] = this.utils.getSessionVal(AgentStatus.dismissedNudges) || [];
    dismissedNudgeList = [...dismissedNudgeList, nudgeId];
    this.utils.setSessionVal([AgentStatus.dismissedNudges], [dismissedNudgeList]);
    this.executeNudgeLogic(this.currentNudgeIndex + 1);
  }

  nudgeClick() {
    if(this.currentNudge?.action) {
      this.openNudge = false;
      let context: Context[] = this.getNudgeContext(this.currentNudge.action);
      if (this.agentDetails.are_agents_online) {
        const additionalSchema: Context = this.getAgentSchema();
        context = [...context, additionalSchema];
        this.snowplowService.trackStructEvent(this.spTracker.labels.inboundNudge,
            this.spTracker.labels.click, this.spTracker.labels.genericNudgeWithAgent,
            this.spTracker.labels.agentsAvailable, this.agentDetails.agents_available_now, context);
      } else {
        this.snowplowService.trackStructEvent(this.spTracker.labels.inboundNudge,
            this.spTracker.labels.click, this.spTracker.labels.genericNudgeNoAgent,'','', context);
      }
      if (this.currentNudge?.action === QueryParamsOptimyScreen.booking) {
        if(this.getBookingConfigStatus()) {
          this.openBooking();
        } else if (this.getLeaveMessageConfigStatus()) {
          this.showLeaveMessageModal();
        }
      } else {
        this.onClickPlugin(true, false)
      }
    }
  }

  getBookingConfigStatus() {
    return !this.agentDetails?.booking_config?.defaults?.disabled;
  }

  getLeaveMessageConfigStatus() {
    return !this.agentDetails?.booking_config?.defaults?.leave_message_disabled;
  }

  showLeaveMessageModal(queueId?: number) {
    this.loggingService.initializeLogging$.next(true);
    this.queueId = queueId ? queueId : this.agentDetails?.default_queue_id;
    this.openModal.next();
    this.showLeaveMessage = true;
  }

  openBooking() {
    this.loggingService.initializeLogging$.next(true);
    this.showBooking = true;
    this.setVariablesForDirectUrl();
    this.navigateToBooking(QueryParamsOptimyScreen.booking);
  }

  getRoomFromCode() {
    this.isFirst = false;
    this.showLauncher = false;
    const isMinimized = this.utils.getLocalVal(PluginStates.callInfo, CallInfoStates.isWindowMinimized);
    const lastCallTime = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.lastCallTimeStamp);
    this.sharedService.minimizeApp(!!isMinimized);
    this.videoCallService.getRoomFromInvite(this.invitationCode).subscribe((res: InviteData) => {
      this.defaultInviteLinkTracker(res.queue_position_id);
      this.utils.setLocalVal(PluginStates.roomInfo,
        [RoomInfoStates.queuePositionId, RoomInfoStates.roomName, RoomInfoStates.startDt,
        RoomInfoStates.closeDt, RoomInfoStates.roomStatus, RoomInfoStates.inviteLink],
        [res?.queue_position_id, res?.room_name, res?.start_dt, res?.closed, res?.status, res?.guest_link]);
      this.sharedService.checkCurrentState(false);
      if (res?.status === CallStatus.missed) {
        this.cleanCallInfoFromLocalStorage();
        this.navigateToClose();
        return;
      } else if (res?.status === CallStatus.cancelled) {
        this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.roomInfo], [RoomInfoStates.guestToken]);
        this.callCompleted();
      } else if (res?.status === CallStatus.closed) {
        if (res?.outbound && !res?.outbound_accepted_timestamp) {
          this.cleanCallInfoFromLocalStorage();
          this.sharedService.showAppLauncher(true);
        } else {
          this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.roomInfo], [RoomInfoStates.guestToken]);
          if(lastCallTime){
            if(this.utils.getAndCheckLastCallStamp(lastCallTime)){
              this.callCompleted();
            } else {
              this.sharedService.showLauncher$.next(true);
            }
            return;
          }
          this.callCompleted();
        }
      } else if (res?.status === CallStatus.ad_hoc) {
        this.loggingService.initializeLogging$.next(true);
        return this.roomInfo?.guestToken ? this.callCompleted() : this.navigateUserInfo(true);
      } else if (res?.status === CallStatus.pending || res?.status === CallStatus.routing) {
        if(res?.status === CallStatus.pending){
          this.loggingService.initializeLogging$.next(true);
          this.utils.checkAndSetGuestStatus(GuestStatus.inQueue, RoomInfoStates.guestToken);
        }
        return res?.start_dt ? (this.roomInfo?.guestToken ? this.callCompleted() :
          this.navigateUserInfo(true)) : this.resumeWaiting();
      } else if (res?.status === CallStatus.in_progress) {
        this.loggingService.initializeLogging$.next(true);
        this.gtmService.sendGtm(GtmTrackers.optimyCallJoined);
        this.checkRoomStatusAndConnect(res?.room_name);
      } else if (res?.status === CallStatus.scheduled && !this.getUrlParameter('optimy_screen', null)) {
        this.utils.checkAndSetGuestStatus(GuestStatus.inQueue, RoomInfoStates.guestToken);
        this.loggingService.initializeLogging$.next(true);
        this.setLocalStorageValueForBooking(res);
        this.router.navigate([{ outlets: { plugin: [RoutesUrls.call_in_future] } }], {});
      } else {
        if (this.getUrlParameter('optimy_screen', null)) {
          this.setLocalStorageValueForBooking(res);
          this.checkRoomStatus();
        }
      }
    }, (error: HttpErrorResponse) => {
      console.log('error with invite read', error);
      if (error.error.includes(InviteLinkStatus.expired)) {
        if(lastCallTime){
          if(this.utils.getAndCheckLastCallStamp(lastCallTime)){
            this.expiredLink = true;
          } else {
            this.sharedService.showLauncher$.next(true);
          }
          return;
        }
        this.expiredLink = true;
      } else {
        this.navigateToClose();
      }
    });
  }

  defaultInviteLinkTracker(queueId: number) {
    const currentLocation = location.href;
    if ((currentLocation.includes(InviteCodeParams.code) ||
      currentLocation.includes(InviteCodeParams.optimyCode)) && !currentLocation.includes(InviteCodeParams.optimyScreen)) {
      this.snowplowService.trackStructEvent(this.spTracker.labels.inviteLink, this.spTracker.labels.default,
        this.spTracker.labels.externalLink, this.spTracker.labels.callId, queueId);
    }

  }

  checkCloseModalEvent(value: boolean) {
    if (value) {
      this.closeModal.next();
      this.showCancelBookingModal = false;
      this.showLeaveMessage = false;
      this.cleanCallInfoFromLocalStorage();
    }
  }

  private cleanCallInfoFromLocalStorage(): void {
    this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.bookingSessionInfo, PluginStates.outboundFirstInitiated]);
  }

  setLocalStorageValueForBooking(res: any) {
    const code = this.utils.getCodeFromInviteLink(res?.guest_link);
    this.utils.setSessionVal([RoomInfoStates.queueInviteCode], [code]);
    this.utils.setLocalVal(PluginStates.bookingSessionInfo, ['response'], [res]);
  }

  checkRoomStatusAndConnect(roomName: string) {
    this.videoCallService.getRoomStatus(roomName)
      .pipe(
        catchError(() => {
          return of(null);
        }),
        takeUntil(this.destroy$),
      ).subscribe((roomInfo: any) => {
        this.sharedService.checkCurrentState(false);
        if (roomInfo) {
          this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.isVideoCallStarted], [true]);
        }
        this.navigateInProgressCall();
      });
  }

  navigateInProgressCall() {
    if (!this.roomInfo?.guestToken) {
      this.navigateUserInfo(true);
    } else {
      this.resumeCall();
    }
  }

  resumeCall() {
    this.utils.checkAndSetGuestStatus(GuestStatus.inCall, this.roomInfo?.guestToken);
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.video_call] } }],
      { queryParams: { resumeCall: true } });
  }

  resumeWaiting() {
    this.showLauncher = true;
    this.isCallWaiting = true;
    this.fabCollapse = true;
    this.utils.checkAndSetGuestStatus(GuestStatus.inQueue, this.roomInfo?.guestToken);
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.video_call] } }],
      { queryParams: { resumeWaiting: true } });
  }

  callCompleted() {
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.video_call] } }],
      { queryParams: { callCompleted: true } });
  }

  navigateUserInfo(fromInviteLink: boolean) {
    this.utils.setLocalVal(null, [PluginStates.isJoinCall], [fromInviteLink]);
    this.getAgentInfo(false).then(() => {
      this.showLauncher = true;
      this.isCallWaiting = true;
      this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.roomInfo], [RoomInfoStates.timeKillerAnswers, RoomInfoStates.timePauseObj]);
      this.utils.storeValueForUserInfoAndDevice(this.agentDetails?.default_queue_id, this.agentDetails?.tenant_id);
      this.router.navigate([{ outlets: { plugin: [RoutesUrls.video_call] } }], {});
      this.openNudge = false;
    });
  }

  updateIp() {
    this.utils.getIPAddress().subscribe((ip: any) => {
      this.ip = ip;
    });
  }

  navigateToBooking(bookingStatus: string) {
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.live_call, bookingStatus] } }], {});
  }

  openBubble() {
    if (this.fabCollapse) {
      this.queuePositionId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
      this.buttonList.length ? this.hideFabItems() : this.showFabItems();
      this.sharedService.fabOpenAction(
        { clickTime: this.utils.getTimeInEpoxy(true), isOpen: !!this.buttonList.length })
    }
  }

  showFabItems() {
    this.buttonList = this.fabButtons;
  }

  hideFabItems() {
    this.buttonList = [];
  }

  triggerFabAction(action: string) {
    this.openBubble();
    this.sharedService.executeFabAction(action);
  }


  fabClick(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    if (this.isCallWaiting) {
      return;
    }
    if (this.isOutboundCall) {
      this.declineOutboundCall()
    } else {
      this.onClickPlugin(false, false);
    }
  }

  onClickPlugin(isNudgeClick: boolean, isExternalLink: boolean) {
    this.gtmService.sendGtm(GtmTrackers.optimyClick);
    this.isFirst = false;
    const stateValue = this.getSetRoomInfo();
    if (isExternalLink || !isNudgeClick) {
      this.emitInteractionDetectedForRecording();
    }
    if (!stateValue) {
      if (!isExternalLink && !isNudgeClick) {
        let context: Context[] = this.getFabContext();
        if(this.agentDetails.are_agents_online) {
          const additionalSchema: Context = this.getAgentSchema();
          context = [...context, additionalSchema];
        }
        this.snowplowService.trackStructEvent(this.spTracker.labels.inboundFab, this.spTracker.labels.click,
          this.agentDetails.are_agents_online ? this.spTracker.labels.genericFabWithAgents : this.spTracker.labels.genericFabNoAgent,
          this.agentDetails.are_agents_online ? this.spTracker.labels.agentsAvailable : '', this.agentDetails.agents_available_now, context);
      }
      this.showAppWindow = true;
      this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
      this.agentsOnline = !!this.agentDetails.are_agents_online;
      const routingEnabled = this.agentDetails?.join_queue_config?.routing_enabled;
      if (this.agentsOnline || routingEnabled) {
        this.showLauncher = false;
        this.navigateUserInfo(false);
        return;
      } else {
        this.openNudge = false;
        this.checkBookingAndLeaveMessageStatus();
      }
    } else {
      this.snowplowService.trackStructEvent(this.spTracker.labels.inboundFab, this.spTracker.labels.localNotClean,
        this.agentDetails.are_agents_online ? this.spTracker.labels.genericFabWithAgents : this.spTracker.labels.genericFabNoAgent);
      this.openNudge = false;
    }
  }

  checkBookingAndLeaveMessageStatus() {
    if (this.getBookingConfigStatus() && this.getLeaveMessageConfigStatus()) {
      this.showNoAgentModal = true;
    } else if (this.getBookingConfigStatus()) {
      this.openBooking();
      this.sharedService.showBookingAndCheckPreviousState(true);
    } else if (this.getLeaveMessageConfigStatus()) {
      this.showLeaveMessageModal();
    }
  }

  close() {
    this.utils.saveInfoForCobrowse();
    const callStatus = this.utils.getSessionVal(PluginStates.callStatus);
    if (callStatus) {
      this.utils.checkStatusAndRedirect();
      this.utils.checkAndSetGuestStatus(GuestStatus.available, this.roomInfo?.guestToken);
      this.utils.setSessionStorage();
      this.updateLocalVariables();
      this.closeCobrowse();
      this.utils.removeSessionStoreVal(Constants.optimySessionStore, [PluginStates.callStatus]);
      if (!this.onCall) {
        this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.deviceInfo, PluginStates.outboundFirstInitiated]);
      }
      this.sharedService.dropCall();
      return;
    } else if (location?.href?.includes(`plugin:${RoutesUrls.user_info_form}`)) {
      const customConfigData = this.agentDetails?.join_queue_config?.custom_form?.pages;
      this.snowplowService.trackStructEvent(this.invitationCode ? this.spTracker.labels.inviteLink :
        this.spTracker.labels.inboundForm, this.spTracker.labels.close,
        customConfigData ? this.spTracker.labels.customForm : this.spTracker.labels.genericForm, '', 0);
      const stateValue = this.getSetRoomInfo();
      if (stateValue) {
        return;
      } else {
        this.closePluginWithoutFeedback();
      }
    } else {
      this.closePluginWithoutFeedback();
    }
  }

  closePluginWithoutFeedback() {
    const currentRoomInfo = this.utils.getLocalVal(PluginStates.roomInfo) as RoomInfo;
    this.utils.checkAndSetGuestStatus(GuestStatus.available, currentRoomInfo?.guestToken);
    this.sharedService.dropCall();
    this.showAppWindow = false;
    this.showLauncher = true;
    this.isOutboundCall = false;
    this.closeCobrowse();
    this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.outboundFirstInitiated]);
    this.utils.removeSessionStorage();
    setTimeout(() => {
      this.navigateToClose();
    }, 200);
  }

  navigateToClose() {
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }]);
    this.hideFab = false;
  }

  getActivatedRoute() {
    const url = this.router?.url;
    return url.substring(url.lastIndexOf(':') + 1, url.lastIndexOf(')'));
  }

  minimize() {
    this.showAppWindow = !this.showAppWindow;
    this.utils.setLocalVal(PluginStates.callInfo, [CallInfoStates.isWindowMinimized], [!this.showAppWindow]);
  }

  checkAndGetInviteCode(name: string, url: string | any) {
    let inviteCode = this.getUrlParameter('optimy_code', url);
    if (inviteCode?.length !== 32 && inviteCode?.length !== 8) {
      inviteCode = this.getUrlParameter(name, url);
    }
    return inviteCode;
  }

  getUrlParameter(name: string, url: string | any) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = url ? regex.exec(url) : regex.exec(location.href);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  setPluginPosition() {
    if (this.pluginPosition) {
      this.updatePosBasedConfig();
    } else {
      this.pluginDiv.nativeElement.style.bottom = '16px';
      this.pluginDiv.nativeElement.style.right = '16px';
    }
  }

  declineOutboundCall() {
    this.gtmService.sendGtm(GtmTrackers.optimyOutboundRejected);
    this.hideFab = true;
    this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    this.snowplowService.trackStructEvent(this.spTracker.labels.outboundRequest,
      this.spTracker.labels.close, this.spTracker.labels.requestPage,
      this.spTracker.labels.callId, this.roomInfo?.queuePositionId);
    this.sharedService.rejectOutboundCall(true);
  }

  updatePosBasedConfig() {
    this.pluginDiv.nativeElement.style.bottom = this.pluginPosition?.bottom ?? '16px';
    switch (this.pluginPosition?.position) {
      case Positions.bottom_left:
        this.pluginDiv.nativeElement.style.left = this.isOnMobile() ? '16px' : (this.pluginPosition?.left ?? '16px');
        break;
      case Positions.bottom_right:
        this.pluginDiv.nativeElement.style.right = this.isOnMobile() ? '16px' : (this.pluginPosition?.right ?? '16px');
        break;
      default:
        this.pluginDiv.nativeElement.style.right = this.isOnMobile() ? '16px' : (this.pluginPosition?.right ?? '16px');
        break;
    }
  }

  checkFabHide() {
    const fabConfig: any = this.utils.getSessionVal(AgentStatus.agentStatusInfo)?.fab_config;
    if (fabConfig) {
      return fabConfig?.fab_hide ?
        this.checkFabHideOrShow(fabConfig?.whitelist, true)
        : this.checkFabHideOrShow(fabConfig?.blacklist, false);
    } else {
      return true;
    }
  }

  checkFabHideOrShow(urlList: any, value: boolean): boolean {
    let currentUrl = location.href.toLowerCase();
    let updatedUrlList: any = [];
    urlList?.forEach((list: string)=> {
      let regexUrl = list.replace(/\//g, '\\/');
       regexUrl = regexUrl.replace(/\*/g, '[a-zA-Z0-9-._~:/?#[\\]@!$&\'()*+,;=%&]*');
       regexUrl = `^${regexUrl}$`
       updatedUrlList.push(regexUrl);
    });
    const anyMatchingUrl = updatedUrlList?.find((list: string)=> currentUrl.match(new RegExp(list.toLowerCase())));
    if(anyMatchingUrl) {
      return value
    } else {
      return !value
    }
  }

  private getSetRoomInfo(): boolean {
    this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    this.invitationCode = this.utils.getCodeFromInviteLink(this.roomInfo?.inviteLink);
    if (this.invitationCode && this.roomInfo?.userInfo) {
      this.getRoomFromCode();
      return true;
    }
    return false;
  }

  getNudgeContext(action: string): Context[] {
    const nudeIdentifier = this.currentNudge.content[this.language]?.nudge_identifier;
    return [
      {
        schema: this.spTracker.schemas.nudgeSchema,
        data: {
          id: nudeIdentifier,
          action: action,
          design_version: `${this.spTracker.schemas.schemaNudgeDesignVersion}-1-0`,
          config_version:  this.agentDetails.fab_config?.nudge_config_version?.toString(),
        }
      }
    ];
  }

  getFabContext(): Context[] {
    return [
      {
        schema: this.spTracker.schemas.fabSchema,
        data: {
          action: this.agentDetails.are_agents_online ? this.spTracker.labels.joinQueue : this.spTracker.labels.schedule,
          design_version: this.agentDetails.are_agents_online ? `${this.spTracker.schemas.schemaFabOnlineDesignVersion}-1-0` :
              `${this.spTracker.schemas.schemaFabOfflineDesignVersion}-1-0`,
          config_version:  this.agentDetails.fab_config?.fab_config_version,
          type: this.spTracker.schemas.genericType
        }
      }
    ];
  }

  getAgentSchema(): Context {
    return  {
      schema: this.spTracker.schemas.agentSchema,
      data: {
        online_count:this.agentDetails.are_agents_online,
        available_count: this.agentDetails.agents_available_now
      }
    }
  }

  getLaunchSchema(type: string, action: string): Context[] {
    return [{ schema: this.spTracker.schemas.launchSchema,
      data: {
        action: action,
        type: type
      }
    }]
  }

  @HostListener('window:resize')
  appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    this.isMobile = this.utils.checkAndGetDeviceType() === 'mobile';
    this.mobileTablet = this.isOnMobile();
  }

  @HostListener('document:visibilitychange')
  updatePageTileOnVisibilityChange() {
    const queueId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId) || '';
    if (!document.hidden) {
      this.utils.updateBrowserTitle(false, true);
      this.snowplowService.trackStructEvent(this.spTracker.categories.tabActivity,
          this.spTracker.actions.active, this.spTracker.labels.tab, this.spTracker.properties.callId, queueId);
    } else {
      this.snowplowService.trackStructEvent(this.spTracker.categories.tabActivity,
          this.spTracker.actions.inactive, this.spTracker.labels.tab, this.spTracker.properties.callId, queueId);
    }
  }
}
